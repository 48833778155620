@import '../../theme';

.auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20vh;
    width: 100%;

    .auth__form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: center;
        min-width: 270px;

        .button {
            background-color: $componentBackgroundColor;
            color: $textInComponent;
            border: none;
            border-radius: 6px;
            padding: 12px 20px;
            font-weight: 700;
            font-size: 15px;
        }

        .input {
            border-radius: 6px;
            padding: 12px 20px;
        }
    }
}