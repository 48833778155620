@import './theme';

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #efefef;
    color: #151515;
}

button {
    cursor: pointer;
}

button:hover {
    filter: brightness(110%);
}

button:active {
    filter: brightness(120%);
}