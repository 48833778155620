.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .green {
        color: green;
    }

    .red {
        color: red;
    }

    .main__section {
        background-color: #fff;
        padding: 20px;
        margin-top: 50px;
        max-width: 900px;
        width: 100%;
        box-shadow: 2px 2px 10px #33333340;
        border-radius: 5px;

        .section__title {
            margin: 0 0 20px
        }
    }

    .card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        box-shadow: 2px 2px 10px #33333370;
        padding: 10px 10px 0;
        margin-bottom: 20px;
        background-color: rgb(186, 255, 186);
        border-radius: 8px;

        .card__header {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            margin-bottom: 10px;

            button {
                padding: 5px 10px;
                border: none;
                background-color: teal;
                color: #fff;
                font-weight: 500;
                border-radius: 6px
            }

            .card__key {
                filter: blur(4px);
                margin: 0
            }

            .card__key:hover {
                filter: blur(0px);
            }
        }

        p {
            margin: 0;
        }

        .card__buttons {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 10px;

            p {
                display: block;
                margin-right: auto;
            }

            button {
                padding: 5px 10px;
                border: none;
                background-color: teal;
                color: #fff;
                font-weight: 500;
                border-radius: 6px 6px 0 0;
            }
        }
    }

    .section__addBlock {
        display: flex;
        justify-content: space-between;
        flex-shrink: 0;
        gap: 20px;

        input {
            width: 100%;
            padding: 12px 20px;
            border-radius: 12px;
            border: none;
            box-shadow: 0 0 3px #333;
        }

        button {
            flex-shrink: 0;
            padding: 12px 20px;
            background-color: teal;
            color: #fff;
            font-weight: 700;
            border: none;
            border-radius: 8px;
        }
    }

    .used {
        background-color: rgb(255, 188, 188);
    }
}